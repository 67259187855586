<template>
  <div :class="class">
    <svg  viewBox="0 0 299 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M61.8192 4.17639H68.426V25.5088H61.8192V4.17639Z" fill="#EF4D5B"/>
<path d="M72.208 10.0558H77.7239V12.5707C78.3993 11.6728 79.2512 10.9225 80.2273 10.3659C81.187 9.89888 82.2457 9.67165 83.3125 9.70365C84.9508 9.70365 86.2267 10.1923 87.1403 11.1697C88.0539 12.1471 88.5183 13.6527 88.5336 15.6865V25.5087H82.5967V17.011C82.5967 16.0425 82.4206 15.3535 82.0608 14.9516C81.8667 14.7432 81.6285 14.5808 81.3636 14.4762C81.0987 14.3715 80.8138 14.3273 80.5297 14.3468C80.1954 14.338 79.8637 14.407 79.5608 14.5486C79.2578 14.6901 78.9919 14.9001 78.7842 15.1621C78.3402 15.7057 78.1143 16.6932 78.1143 18.0866V25.5087H72.208V10.0558Z" fill="#EF4D5B"/>
<path d="M108.071 4.17639V25.5088H102.528V23.2121C101.954 23.998 101.232 24.6649 100.404 25.1758C99.5205 25.6279 98.5403 25.8579 97.5482 25.8457C95.4429 25.8457 93.8352 25.0444 92.7251 23.4418C91.5782 21.7172 90.9962 19.6784 91.06 17.6082C91.06 15.0563 91.6738 13.0965 92.9012 11.7287C93.4841 11.067 94.2051 10.5414 95.0133 10.1889C95.8216 9.83634 96.6973 9.66551 97.5788 9.68845C98.4384 9.67741 99.2917 9.83611 100.09 10.1554C100.85 10.4723 101.532 10.9488 102.092 11.5526V4.17639H108.071ZM102.134 17.7383C102.134 16.5262 101.879 15.6216 101.368 15.0244C101.131 14.7379 100.833 14.5086 100.495 14.3535C100.157 14.1985 99.788 14.1216 99.4162 14.1287C99.0791 14.1267 98.7464 14.2044 98.4451 14.3554C98.1437 14.5064 97.8823 14.7265 97.6822 14.9976C97.2152 15.5692 96.9817 16.5288 96.9817 17.8761C96.9817 19.1393 97.2228 20.0618 97.7013 20.6513C97.9101 20.9262 98.1799 21.1489 98.4895 21.3017C98.799 21.4544 99.1398 21.5332 99.4851 21.5317C99.8469 21.5366 100.205 21.4592 100.533 21.3054C100.86 21.1515 101.149 20.9253 101.376 20.6437C101.881 20.0465 102.134 19.0781 102.134 17.7383Z" fill="#EF4D5B"/>
<path d="M128.45 19.2504H116.584C116.619 20.0207 116.886 20.7625 117.349 21.3786C117.622 21.7047 117.965 21.9644 118.353 22.1382C118.741 22.312 119.164 22.3952 119.589 22.3815C120.171 22.3821 120.743 22.2317 121.25 21.9451C121.651 21.6849 122.001 21.3542 122.283 20.969L128.113 21.5049C127.374 22.9009 126.254 24.0584 124.883 24.8428C123.622 25.519 121.813 25.8572 119.455 25.8572C117.413 25.8572 115.803 25.5675 114.624 24.9882C113.406 24.3734 112.393 23.4187 111.707 22.2399C110.916 20.8996 110.518 19.3634 110.559 17.8073C110.559 15.3932 111.333 13.4385 112.882 11.9431C114.431 10.4477 116.569 9.69995 119.294 9.69995C121.503 9.69995 123.252 10.0368 124.53 10.7067C125.785 11.3476 126.807 12.3648 127.455 13.6158C128.126 14.8917 128.462 16.5505 128.462 18.592L128.45 19.2504ZM122.425 16.4139C122.306 15.2656 122 14.4503 121.499 13.9565C121.202 13.6936 120.855 13.4942 120.479 13.3706C120.103 13.247 119.705 13.2017 119.311 13.2375C118.916 13.2734 118.533 13.3896 118.185 13.5791C117.838 13.7686 117.532 14.0273 117.288 14.3393C116.875 14.9581 116.638 15.6781 116.603 16.4216L122.425 16.4139Z" fill="#EF4D5B"/>
<path d="M130.911 10.0558H136.427V12.5707C137.109 11.6714 137.967 10.9211 138.95 10.3658C139.903 9.90334 140.953 9.67625 142.012 9.70363C143.655 9.70363 144.939 10.1923 145.863 11.1697C146.786 12.1471 147.25 13.6527 147.252 15.6865V25.5087H141.308V17.0109C141.308 16.0425 141.128 15.3535 140.772 14.9516C140.578 14.7432 140.339 14.5808 140.074 14.4761C139.81 14.3715 139.525 14.3273 139.241 14.3468C138.906 14.3376 138.574 14.4066 138.271 14.5481C137.968 14.6897 137.703 14.8999 137.495 15.1621C137.049 15.7056 136.825 16.6805 136.825 18.0865V25.5087H130.911V10.0558Z" fill="#EF4D5B"/>
<path d="M157.342 4.17639V10.0559H160.6V14.3928H157.342V19.8666C157.305 20.3115 157.37 20.7591 157.53 21.1757C157.638 21.3439 157.79 21.479 157.969 21.5661C158.149 21.6533 158.349 21.6891 158.548 21.6695C159.175 21.6314 159.792 21.5025 160.382 21.2867L160.818 25.3748C159.472 25.6829 158.096 25.8446 156.715 25.8571C155.241 25.8571 154.154 25.6658 153.457 25.2906C152.764 24.9093 152.224 24.301 151.926 23.5681C151.589 22.8025 151.421 21.5547 151.421 19.8245V14.3928H149.239V10.0559H151.421V7.21568L157.342 4.17639Z" fill="#EF4D5B"/>
<path d="M168.523 15.0627L162.862 14.4656C163.01 13.6332 163.323 12.8391 163.785 12.1306C164.245 11.5118 164.841 11.0064 165.526 10.6531C166.229 10.2985 166.986 10.0619 167.765 9.95257C168.785 9.78163 169.817 9.69839 170.851 9.70377C172.283 9.6781 173.715 9.778 175.13 10.0023C176.12 10.1659 177.046 10.5959 177.81 11.2464C178.36 11.7533 178.771 12.3924 179.004 13.1029C179.283 13.837 179.43 14.6144 179.44 15.3996V22.2207C179.427 22.7943 179.473 23.3677 179.578 23.9318C179.721 24.4781 179.924 25.0071 180.183 25.5088H174.636C174.462 25.2204 174.315 24.9168 174.196 24.6016C174.106 24.2787 174.039 23.95 173.993 23.6179C173.333 24.2878 172.554 24.8302 171.697 25.2179C170.532 25.6678 169.29 25.8863 168.041 25.861C166.201 25.861 164.798 25.4335 163.83 24.5787C163.373 24.1908 163.007 23.7065 162.759 23.1605C162.511 22.6145 162.387 22.0204 162.395 21.4207C162.355 20.358 162.725 19.3204 163.429 18.5231C164.118 17.7575 165.394 17.1948 167.256 16.835C169.479 16.391 170.92 16.0784 171.578 15.8972C172.29 15.701 172.99 15.4658 173.676 15.1929C173.676 14.4656 173.523 13.9565 173.224 13.6617C172.925 13.367 172.393 13.2254 171.635 13.2254C170.879 13.1767 170.124 13.3383 169.454 13.6924C169 14.0337 168.673 14.5156 168.523 15.0627V15.0627ZM173.66 18.1747C172.846 18.4656 171.997 18.7208 171.111 18.9403C169.909 19.258 169.147 19.5757 168.814 19.8858C168.659 20.0271 168.535 20.1994 168.45 20.3915C168.365 20.5837 168.321 20.7915 168.321 21.0016C168.321 21.2117 168.365 21.4195 168.45 21.6116C168.535 21.8038 168.659 21.976 168.814 22.1174C169.218 22.4413 169.73 22.6 170.246 22.5614C170.891 22.5647 171.525 22.3998 172.087 22.0829C172.596 21.8191 173.017 21.4119 173.297 20.9116C173.555 20.3483 173.677 19.7319 173.653 19.1125L173.66 18.1747Z" fill="#EF4D5B"/>
<path d="M189.519 4.17639V10.0559H192.78V14.3928H189.519V19.8666C189.483 20.3118 189.548 20.7595 189.71 21.1757C189.818 21.3442 189.97 21.4796 190.15 21.5667C190.329 21.6539 190.53 21.6895 190.729 21.6695C191.355 21.6314 191.973 21.5025 192.562 21.2867L192.999 25.3748C191.652 25.6826 190.276 25.8444 188.895 25.8571C187.418 25.8571 186.334 25.6658 185.634 25.2906C184.943 24.9068 184.403 24.2994 184.103 23.5681C183.768 22.8025 183.601 21.5547 183.601 19.8245V14.3928H181.419V10.0559H183.601V7.21568L189.519 4.17639Z" fill="#EF4D5B"/>
<path d="M195.601 4.17639H201.523V8.20708H195.601V4.17639ZM195.601 10.0559H201.523V25.5088H195.601V10.0559Z" fill="#EF4D5B"/>
<path d="M204.092 17.8264C204.058 16.7418 204.253 15.6623 204.664 14.658C205.075 13.6536 205.692 12.7469 206.476 11.9966C208.069 10.4655 210.219 9.69995 212.926 9.69995C216.019 9.69995 218.357 10.5931 219.939 12.3794C221.224 13.8563 221.907 15.7623 221.853 17.7192C221.889 18.8044 221.698 19.8852 221.291 20.8919C220.884 21.8986 220.271 22.809 219.491 23.5643C217.914 25.0801 215.733 25.8393 212.949 25.8419C210.464 25.8419 208.455 25.2103 206.924 23.9471C206.012 23.2144 205.282 22.2806 204.79 21.2188C204.299 20.157 204.06 18.996 204.092 17.8264V17.8264ZM210.028 17.8264C210.028 19.2044 210.308 20.2252 210.867 20.8887C211.124 21.1967 211.446 21.4444 211.81 21.6144C212.174 21.7844 212.571 21.8725 212.972 21.8725C213.374 21.8725 213.77 21.7844 214.134 21.6144C214.498 21.4444 214.82 21.1967 215.077 20.8887C215.621 20.2341 215.889 19.2082 215.889 17.7537C215.889 16.4139 215.613 15.4174 215.062 14.7642C214.817 14.4549 214.506 14.2055 214.15 14.0351C213.794 13.8646 213.405 13.7775 213.01 13.7804C212.601 13.772 212.196 13.8573 211.825 14.0298C211.454 14.2023 211.128 14.4574 210.871 14.7756C210.309 15.434 210.028 16.4458 210.028 17.8111V17.8264Z" fill="#EF4D5B"/>
<path d="M224.31 10.0559H229.826V12.5707C230.509 11.6725 231.367 10.9224 232.348 10.3659C233.308 9.89936 234.367 9.67215 235.434 9.70369C237.077 9.70369 238.361 10.1924 239.284 11.1697C240.208 12.1471 240.671 13.6527 240.674 15.6866V25.5087H234.718V17.011C234.718 16.0426 234.538 15.3536 234.178 14.9516C233.986 14.7458 233.751 14.5849 233.49 14.4804C233.228 14.3759 232.947 14.3303 232.666 14.3468C232.332 14.3371 232 14.4058 231.697 14.5474C231.394 14.6889 231.128 14.8995 230.921 15.1622C230.474 15.7057 230.251 16.6805 230.251 18.0866V25.5087H224.31V10.0559Z" fill="#EF4D5B"/>
<path d="M242.818 21.2178L248.693 20.6628C248.866 21.2587 249.224 21.7842 249.715 22.1633C250.237 22.4885 250.846 22.6462 251.461 22.615C252.116 22.6547 252.767 22.4756 253.31 22.1059C253.465 22.0017 253.593 21.8611 253.681 21.6963C253.77 21.5316 253.817 21.3476 253.819 21.1604C253.815 20.9484 253.75 20.742 253.631 20.5665C253.512 20.391 253.345 20.2542 253.149 20.1729C252.324 19.8797 251.471 19.6756 250.603 19.5642C249.149 19.3508 247.715 19.0167 246.316 18.5652C245.509 18.2589 244.806 17.7275 244.291 17.034C243.749 16.319 243.46 15.4434 243.472 14.546C243.45 13.5929 243.763 12.6623 244.356 11.9163C245 11.1464 245.848 10.5744 246.802 10.2665C248.155 9.8578 249.566 9.67291 250.978 9.71909C252.823 9.71909 254.182 9.86072 255.063 10.144C255.891 10.3939 256.646 10.8435 257.26 11.4531C257.907 12.143 258.404 12.9587 258.722 13.8493L253.107 14.4043C252.999 13.9658 252.74 13.5794 252.376 13.3134C251.874 12.9896 251.285 12.8267 250.687 12.8464C250.174 12.8047 249.662 12.9295 249.225 13.2024C249.085 13.2992 248.971 13.4285 248.891 13.5791C248.812 13.7297 248.77 13.8973 248.77 14.0675C248.77 14.2522 248.825 14.4327 248.929 14.5856C249.033 14.7385 249.18 14.8567 249.352 14.9249C250.164 15.2091 251.011 15.3804 251.87 15.434C253.369 15.5541 254.846 15.8681 256.265 16.368C257.154 16.7019 257.925 17.2904 258.481 18.0599C258.982 18.7695 259.25 19.6178 259.247 20.4867C259.236 21.4145 258.956 22.3191 258.439 23.0897C257.813 24.0024 256.926 24.7043 255.893 25.1031C254.735 25.6135 253.155 25.8686 251.155 25.8686C248.33 25.8686 246.319 25.4654 245.122 24.6591C244.531 24.265 244.023 23.7582 243.627 23.1677C243.232 22.5773 242.957 21.9147 242.818 21.2178V21.2178Z" fill="#EF4D5B"/>
<path d="M262.045 19.5909H268.376V25.5087H262.045V19.5909Z" fill="#EF4D5B"/>
<path d="M271.867 4.17639H277.792V8.20708H271.867V4.17639ZM271.867 10.0559H277.792V25.5088H271.867V10.0559Z" fill="#EF4D5B"/>
<path d="M280.357 17.8264C280.324 16.7415 280.519 15.6616 280.931 14.6572C281.342 13.6528 281.96 12.7463 282.745 11.9966C284.335 10.4655 286.484 9.69995 289.192 9.69995C292.287 9.69995 294.626 10.5931 296.208 12.3794C297.493 13.8563 298.176 15.7623 298.122 17.7192C298.158 18.8048 297.967 19.8859 297.559 20.8928C297.152 21.8996 296.538 22.8097 295.756 23.5643C294.179 25.0801 291.999 25.8393 289.214 25.8419C286.729 25.8419 284.721 25.2103 283.19 23.9471C282.277 23.2144 281.547 22.2806 281.056 21.2188C280.564 20.157 280.325 18.996 280.357 17.8264ZM286.298 17.8264C286.298 19.2044 286.573 20.2226 287.132 20.8887C287.39 21.1967 287.712 21.4444 288.076 21.6144C288.439 21.7844 288.836 21.8725 289.237 21.8725C289.639 21.8725 290.036 21.7844 290.399 21.6144C290.763 21.4444 291.085 21.1967 291.343 20.8887C291.89 20.2418 292.166 19.1968 292.166 17.7613C292.166 16.4216 291.889 15.4251 291.335 14.7718C291.091 14.4619 290.779 14.2121 290.424 14.0415C290.068 13.871 289.678 13.7843 289.283 13.7881C288.874 13.7796 288.469 13.8649 288.098 14.0374C287.727 14.2099 287.401 14.4651 287.144 14.7833C286.58 15.4366 286.298 16.4458 286.298 17.8111V17.8264Z" fill="#EF4D5B"/>
<path d="M62.2672 29.8916H63.4347L65.762 38.0946L69.0233 29.8916H69.2606L72.4837 38.0946L74.8492 29.8916H75.9976L72.7286 41.2717H72.5181L69.142 32.6629L65.6969 41.2717H65.4864L62.2672 29.8916Z" fill="white"/>
<path d="M77.6472 29.6084H78.7305V34.3664C79.1147 33.8356 79.6081 33.3931 80.1774 33.0687C80.7006 32.7827 81.2883 32.6351 81.8846 32.64C82.4785 32.6299 83.0621 32.7964 83.5612 33.1185C84.0409 33.432 84.4167 33.881 84.6406 34.4085C84.9164 35.2234 85.0346 36.0834 84.989 36.9425V41.2718H83.9057V37.2602C83.9284 36.6099 83.8899 35.9588 83.7909 35.3157C83.6956 34.8276 83.4395 34.3856 83.0636 34.0602C82.6628 33.7639 82.1719 33.6151 81.6741 33.6391C81.0284 33.6355 80.4031 33.8652 79.9133 34.286C79.4082 34.7 79.0488 35.2647 78.8874 35.8975C78.7572 36.6557 78.7059 37.4254 78.7343 38.1942V41.2794H77.651L77.6472 29.6084Z" fill="white"/>
<path d="M94.2867 38.4813L95.1977 38.9597C94.941 39.4933 94.5895 39.9757 94.1604 40.3837C93.7754 40.7399 93.3259 41.0193 92.836 41.2067C92.303 41.4024 91.7386 41.4984 91.1709 41.4899C90.5672 41.521 89.9644 41.4157 89.4071 41.1817C88.8497 40.9478 88.3523 40.5913 87.9517 40.1387C87.203 39.2953 86.7938 38.2042 86.8033 37.0765C86.7929 36.0398 87.1404 35.0312 87.7871 34.2209C88.176 33.7084 88.6819 33.2963 89.2626 33.0193C89.8433 32.7423 90.4819 32.6083 91.1249 32.6285C91.7877 32.6053 92.4464 32.7412 93.0458 33.0249C93.6452 33.3086 94.1678 33.7319 94.57 34.2592C95.1992 35.0846 95.5273 36.1002 95.5001 37.1377H87.9057C87.8941 38.0297 88.2289 38.8914 88.8397 39.5416C89.1263 39.8462 89.4738 40.0871 89.8596 40.2487C90.2454 40.4103 90.6609 40.4889 91.079 40.4794C91.5061 40.4803 91.93 40.4052 92.3307 40.2574C92.7082 40.1237 93.0584 39.9227 93.3642 39.6641C93.7289 39.3179 94.0398 38.9193 94.2867 38.4813V38.4813ZM94.2867 36.2152C94.1771 35.7136 93.9618 35.2412 93.6551 34.8295C93.3599 34.4702 92.9851 34.1845 92.5604 33.9951C92.1116 33.7834 91.6211 33.6749 91.1249 33.6774C90.3397 33.66 89.5777 33.945 88.9967 34.4735C88.5102 34.9491 88.169 35.5531 88.0129 36.2152H94.2867Z" fill="white"/>
<path d="M97.1346 32.8582H98.2409V34.0869C98.5142 33.6593 98.8694 33.2899 99.2859 32.9998C99.624 32.7676 100.024 32.6422 100.434 32.64C100.774 32.6516 101.105 32.7505 101.395 32.9271L100.828 33.8381C100.564 33.7041 100.262 33.6608 99.9704 33.7147C99.6786 33.7687 99.4126 33.9169 99.2131 34.1367C98.8293 34.5133 98.5633 34.9934 98.4476 35.5185C98.2706 36.4775 98.2026 37.4534 98.2447 38.4277V41.2717H97.1385L97.1346 32.8582Z" fill="white"/>
<path d="M108.963 38.4813L109.858 38.9598C109.602 39.4933 109.25 39.9758 108.821 40.3837C108.436 40.74 107.987 41.0193 107.497 41.2067C106.964 41.4024 106.399 41.4984 105.832 41.49C105.228 41.521 104.625 41.4157 104.068 41.1818C103.51 40.9478 103.013 40.5913 102.612 40.1388C101.892 39.3328 101.479 38.2987 101.447 37.2184C101.414 36.1381 101.763 35.0808 102.432 34.2324C102.821 33.7196 103.327 33.3075 103.908 33.0304C104.488 32.7534 105.127 32.6195 105.77 32.6401C106.433 32.6171 107.092 32.7532 107.691 33.0369C108.29 33.3206 108.813 33.7436 109.215 34.2707C109.841 35.0977 110.169 36.1122 110.145 37.1492H102.586C102.575 38.0418 102.911 38.9036 103.523 39.5531C103.81 39.8577 104.157 40.0986 104.543 40.2602C104.929 40.4218 105.344 40.5004 105.763 40.4909C106.19 40.4919 106.614 40.4167 107.014 40.2689C107.392 40.1353 107.742 39.9342 108.048 39.6756C108.41 39.3244 108.718 38.922 108.963 38.4813V38.4813ZM108.963 36.2152C108.853 35.7137 108.638 35.2413 108.331 34.8296C108.035 34.4694 107.659 34.1836 107.233 33.9951C106.785 33.7832 106.296 33.6746 105.801 33.6774C105.016 33.66 104.254 33.9451 103.673 34.4736C103.186 34.9491 102.845 35.5532 102.689 36.2152H108.963Z" fill="white"/>
<path d="M124.729 34.6037L123.868 35.1396C123.522 34.6631 123.064 34.2791 122.535 34.0214C122.006 33.7637 121.421 33.6403 120.833 33.6621C120.363 33.6475 119.895 33.7274 119.456 33.897C119.017 34.0666 118.617 34.3226 118.279 34.6496C117.952 34.9574 117.692 35.3302 117.517 35.7442C117.341 36.1581 117.254 36.604 117.261 37.0535C117.261 37.6615 117.422 38.2587 117.728 38.7837C118.032 39.3143 118.478 39.7502 119.014 40.043C119.575 40.3458 120.203 40.5 120.84 40.4909C121.426 40.5033 122.006 40.3757 122.533 40.1187C123.06 39.8617 123.517 39.4828 123.868 39.0134L124.729 39.5799C124.325 40.1866 123.764 40.6732 123.106 40.9885C122.383 41.3321 121.591 41.5037 120.791 41.49C120.182 41.5086 119.576 41.4063 119.008 41.1888C118.439 40.9713 117.92 40.643 117.479 40.2229C117.056 39.8288 116.72 39.3502 116.493 38.8181C116.267 38.286 116.154 37.7123 116.163 37.1339C116.159 36.3355 116.372 35.551 116.779 34.864C117.181 34.1719 117.766 33.6048 118.471 33.2257C119.209 32.8295 120.037 32.6279 120.875 32.64C121.423 32.638 121.969 32.7246 122.49 32.8965C122.963 33.0428 123.408 33.2666 123.807 33.5587C124.179 33.8444 124.492 34.1992 124.729 34.6037V34.6037Z" fill="white"/>
<path d="M130.31 32.64C130.918 32.6263 131.521 32.7452 132.077 32.9885C132.634 33.2318 133.131 33.5936 133.533 34.0487C134.295 34.8906 134.711 35.9886 134.699 37.1236C134.687 38.2587 134.247 39.3476 133.468 40.1732C133.054 40.5913 132.562 40.9233 132.019 41.1498C131.475 41.3763 130.893 41.493 130.305 41.493C129.716 41.493 129.134 41.3763 128.591 41.1498C128.048 40.9233 127.555 40.5913 127.141 40.1732C126.362 39.3476 125.922 38.2587 125.91 37.1236C125.898 35.9886 126.314 34.8906 127.076 34.0487C127.479 33.5915 127.978 33.2283 128.536 32.9849C129.095 32.7415 129.701 32.6238 130.31 32.64ZM130.31 33.7003C129.875 33.6959 129.444 33.7821 129.044 33.9534C128.644 34.1247 128.284 34.3775 127.987 34.6956C127.672 35.0128 127.424 35.3901 127.257 35.805C127.091 36.2199 127.01 36.664 127.018 37.1109C127.015 37.7072 127.167 38.2942 127.459 38.8143C127.737 39.3232 128.152 39.7447 128.657 40.0315C129.162 40.3128 129.732 40.4603 130.31 40.4603C130.889 40.4603 131.458 40.3128 131.964 40.0315C132.466 39.7435 132.88 39.3222 133.158 38.8143C133.451 38.2945 133.604 37.7076 133.602 37.1109C133.61 36.6634 133.528 36.2187 133.36 35.8037C133.193 35.3887 132.943 35.0118 132.626 34.6956C132.329 34.3783 131.97 34.1261 131.571 33.9548C131.171 33.7835 130.741 33.6969 130.306 33.7003H130.31Z" fill="white"/>
<path d="M144.917 29.6084V41.2718H143.849V39.8248C143.435 40.3461 142.912 40.7717 142.318 41.0727C141.512 41.4569 140.606 41.579 139.728 41.4217C138.849 41.2645 138.041 40.8359 137.419 40.1961C137.008 39.7818 136.684 39.2891 136.467 38.7473C136.25 38.2055 136.144 37.6256 136.156 37.042C136.146 36.464 136.255 35.8902 136.474 35.3553C136.693 34.8204 137.018 34.3355 137.43 33.93C137.825 33.5161 138.3 33.188 138.827 32.9661C139.354 32.7441 139.921 32.6331 140.492 32.64C141.146 32.6304 141.791 32.7815 142.372 33.0802C142.96 33.4007 143.464 33.8543 143.846 34.4047V29.6084H144.917ZM140.588 33.685C139.999 33.6805 139.42 33.8354 138.912 34.1332C138.404 34.4311 137.986 34.8608 137.702 35.3769C137.405 35.894 137.248 36.48 137.248 37.0765C137.248 37.6729 137.405 38.2589 137.702 38.776C137.989 39.2995 138.412 39.7359 138.927 40.0392C139.425 40.3374 139.996 40.4936 140.577 40.4909C141.167 40.4907 141.748 40.3363 142.261 40.043C142.774 39.7608 143.199 39.3405 143.486 38.8296C143.775 38.3021 143.923 37.7087 143.914 37.1071C143.926 36.656 143.847 36.2072 143.681 35.7876C143.516 35.3679 143.267 34.986 142.95 34.6649C142.646 34.3467 142.279 34.0953 141.872 33.9266C141.465 33.7579 141.028 33.6756 140.588 33.685V33.685Z" fill="white"/>
<path d="M154.337 38.4813L155.249 38.9597C154.992 39.4933 154.64 39.9757 154.211 40.3837C153.826 40.7399 153.377 41.0193 152.887 41.2067C152.354 41.4024 151.789 41.4984 151.222 41.4899C150.618 41.521 150.015 41.4157 149.458 41.1817C148.901 40.9478 148.403 40.5913 148.002 40.1387C147.254 39.2953 146.845 38.2042 146.854 37.0765C146.844 36.0398 147.191 35.0312 147.838 34.2209C148.227 33.7081 148.732 33.2959 149.313 33.0189C149.894 32.7418 150.533 32.608 151.176 32.6285C151.838 32.6053 152.497 32.7412 153.097 33.0249C153.696 33.3086 154.219 33.7319 154.621 34.2592C155.25 35.0846 155.578 36.1002 155.551 37.1377H147.953C147.942 38.0302 148.278 38.892 148.891 39.5416C149.177 39.8462 149.525 40.0871 149.91 40.2487C150.296 40.4103 150.712 40.4889 151.13 40.4794C151.557 40.4803 151.981 40.4052 152.381 40.2574C152.759 40.1237 153.109 39.9227 153.415 39.6641C153.78 39.3179 154.091 38.9193 154.337 38.4813V38.4813ZM154.337 36.2152C154.228 35.7136 154.013 35.2412 153.706 34.8295C153.411 34.4702 153.036 34.1845 152.611 33.9951C152.162 33.7834 151.672 33.6749 151.176 33.6774C150.39 33.66 149.629 33.945 149.047 34.4735C148.561 34.9491 148.22 35.5531 148.064 36.2152H154.337Z" fill="white"/>
<path d="M161.917 32.8582H163V34.3089C163.301 33.8451 163.691 33.4457 164.148 33.1338C164.667 32.8132 165.265 32.6423 165.875 32.64C166.27 32.6404 166.661 32.7225 167.023 32.8812C167.367 33.0229 167.674 33.24 167.922 33.5166C168.193 33.8582 168.397 34.2478 168.523 34.6649C168.84 34.0575 169.305 33.54 169.875 33.1606C170.395 32.825 171.001 32.6468 171.62 32.6477C172.163 32.6382 172.696 32.7915 173.151 33.0879C173.601 33.3853 173.947 33.8145 174.143 34.3166C174.396 35.0796 174.506 35.8829 174.468 36.686V41.2794H173.4V36.686C173.433 36.0598 173.368 35.4322 173.209 34.8257C173.082 34.4895 172.85 34.2034 172.546 34.0104C172.215 33.7974 171.827 33.6893 171.433 33.7003C170.917 33.697 170.414 33.8593 169.997 34.1635C169.564 34.4719 169.232 34.9023 169.044 35.3999C168.799 36.238 168.697 37.1115 168.742 37.9836V41.2794H167.658V36.9654C167.693 36.2698 167.63 35.5727 167.471 34.8946C167.35 34.5396 167.118 34.2334 166.809 34.0219C166.479 33.7964 166.086 33.6812 165.687 33.6927C165.187 33.6924 164.698 33.8475 164.29 34.1367C163.855 34.4383 163.52 34.8619 163.325 35.3539C163.079 36.1105 162.973 36.9057 163.011 37.7004V41.2641H161.917V32.8582Z" fill="white"/>
<path d="M183.796 38.4813L184.711 38.9597C184.452 39.4917 184.101 39.9737 183.674 40.3837C183.288 40.7392 182.839 41.0184 182.349 41.2067C181.816 41.4023 181.252 41.4984 180.684 41.4899C180.08 41.521 179.477 41.4157 178.919 41.1818C178.361 40.9479 177.863 40.5914 177.461 40.1387C176.712 39.2955 176.303 38.2042 176.313 37.0764C176.301 36.0395 176.648 35.0303 177.297 34.2209C177.685 33.7076 178.191 33.2951 178.772 33.018C179.352 32.7409 179.991 32.6073 180.635 32.6285C181.297 32.6059 181.956 32.7421 182.555 33.0258C183.154 33.3094 183.677 33.7323 184.08 34.2592C184.707 35.0851 185.034 36.1007 185.006 37.1377H177.415C177.401 38.0309 177.738 38.894 178.353 39.5416C178.639 39.8465 178.985 40.0876 179.371 40.2492C179.756 40.4108 180.171 40.4893 180.589 40.4794C181.016 40.4803 181.44 40.4051 181.84 40.2574C182.218 40.1237 182.568 39.9227 182.874 39.6641C183.24 39.3189 183.551 38.9201 183.796 38.4813V38.4813ZM183.796 36.2152C183.687 35.7136 183.471 35.2412 183.165 34.8295C182.87 34.4694 182.495 34.1835 182.07 33.9951C181.622 33.7838 181.133 33.6753 180.638 33.6773C179.852 33.6613 179.09 33.9461 178.506 34.4735C178.021 34.9499 177.68 35.5536 177.523 36.2152H183.796Z" fill="white"/>
<path d="M193.925 38.4813L194.836 38.9598C194.577 39.4924 194.226 39.9746 193.798 40.3837C193.413 40.74 192.964 41.0193 192.474 41.2067C191.941 41.4024 191.377 41.4984 190.809 41.49C190.205 41.5213 189.602 41.4161 189.045 41.1822C188.488 40.9482 187.99 40.5916 187.59 40.1388C186.87 39.3328 186.457 38.2987 186.424 37.2184C186.391 36.1381 186.74 35.0808 187.41 34.2324C187.799 33.7196 188.304 33.3075 188.885 33.0304C189.466 32.7534 190.105 32.6195 190.748 32.6401C191.41 32.6171 192.069 32.7532 192.668 33.0369C193.268 33.3206 193.79 33.7436 194.193 34.2707C194.819 35.0977 195.147 36.1122 195.123 37.1492H187.54C187.529 38.0418 187.866 38.9036 188.478 39.5531C188.764 39.8577 189.112 40.0986 189.498 40.2602C189.883 40.4218 190.299 40.5004 190.717 40.4909C191.144 40.4919 191.568 40.4167 191.969 40.2689C192.346 40.1344 192.696 39.9335 193.002 39.6756C193.367 39.3249 193.678 38.9225 193.925 38.4813V38.4813ZM193.925 36.2152C193.815 35.7137 193.6 35.2413 193.293 34.8296C192.996 34.4702 192.62 34.1845 192.195 33.9951C191.747 33.7832 191.258 33.6746 190.763 33.6774C189.977 33.6622 189.215 33.9468 188.631 34.4736C188.146 34.9495 187.806 35.5535 187.651 36.2152H193.925Z" fill="white"/>
<path d="M197.473 29.7308H198.556V32.8582H200.275V33.7921H198.556V41.2717H197.473V33.7921H195.984V32.8582H197.462L197.473 29.7308Z" fill="white"/>
<path d="M206.086 33.7767L205.389 34.4964C204.955 34.0029 204.345 33.7004 203.69 33.6543C203.354 33.6475 203.03 33.7725 202.786 34.0026C202.668 34.1023 202.572 34.2265 202.506 34.3665C202.44 34.5066 202.405 34.6592 202.403 34.8141C202.414 35.0977 202.523 35.3687 202.713 35.5797C203.09 35.9524 203.531 36.2534 204.015 36.4677C204.642 36.7515 205.203 37.1635 205.661 37.6773C205.942 38.0599 206.091 38.5233 206.086 38.9979C206.092 39.325 206.03 39.6497 205.905 39.952C205.78 40.2543 205.594 40.5275 205.358 40.7549C205.119 40.9915 204.834 41.1772 204.521 41.3009C204.208 41.4246 203.873 41.4836 203.536 41.4745C203.055 41.4749 202.58 41.3663 202.147 41.1568C201.717 40.9565 201.341 40.6575 201.048 40.284L201.73 39.5185C201.938 39.7927 202.203 40.0183 202.507 40.1796C202.811 40.3409 203.147 40.434 203.49 40.4525C203.9 40.4619 204.298 40.3124 204.601 40.0352C204.743 39.9138 204.857 39.763 204.936 39.5932C205.015 39.4234 205.056 39.2386 205.056 39.0515C205.058 38.7485 204.951 38.4549 204.754 38.2247C204.353 37.8512 203.893 37.5483 203.391 37.329C202.795 37.0647 202.264 36.6727 201.837 36.1806C201.565 35.7988 201.421 35.3403 201.427 34.8715C201.419 34.5751 201.473 34.2802 201.586 34.0059C201.698 33.7316 201.867 33.484 202.082 33.2791C202.301 33.068 202.56 32.9029 202.844 32.7938C203.128 32.6846 203.431 32.6336 203.735 32.6437C204.516 32.6412 205.3 33.0188 206.086 33.7767Z" fill="white"/>
<path d="M212.444 41.2718V29.6084H213.527V34.3013C213.941 33.7792 214.463 33.3534 215.058 33.0534C215.864 32.6706 216.769 32.5496 217.647 32.7075C218.525 32.8654 219.332 33.2943 219.954 33.9338C220.365 34.3468 220.688 34.8382 220.905 35.3788C221.122 35.9193 221.228 36.4979 221.217 37.0803C221.227 37.6595 221.119 38.2345 220.899 38.7708C220.68 39.307 220.355 39.7932 219.942 40.2C219.548 40.6142 219.073 40.9426 218.546 41.1646C218.019 41.3865 217.452 41.4973 216.88 41.4899C216.233 41.4996 215.594 41.3484 215.02 41.0497C214.432 40.7284 213.926 40.275 213.542 39.7253V41.2564L212.444 41.2718ZM216.777 40.4449C217.363 40.4506 217.94 40.2971 218.446 40.0009C218.955 39.702 219.375 39.2708 219.659 38.7531C219.956 38.233 220.112 37.6446 220.112 37.0458C220.112 36.4471 219.956 35.8587 219.659 35.3386C219.372 34.8161 218.951 34.3799 218.438 34.0755C217.942 33.7783 217.374 33.6221 216.796 33.6238C216.203 33.6234 215.621 33.7792 215.108 34.0755C214.594 34.359 214.17 34.7807 213.883 35.2927C213.591 35.8207 213.444 36.416 213.454 37.0191C213.442 37.4703 213.521 37.9194 213.686 38.3396C213.851 38.7598 214.099 39.1425 214.415 39.465C214.721 39.7852 215.091 40.0378 215.5 40.2065C215.909 40.3753 216.349 40.4565 216.792 40.4449H216.777Z" fill="white"/>
<path d="M230.136 38.4813L231.051 38.9598C230.792 39.4924 230.441 39.9746 230.013 40.3837C229.628 40.7392 229.179 41.0185 228.689 41.2067C228.156 41.4024 227.592 41.4984 227.024 41.49C226.42 41.5211 225.816 41.4158 225.258 41.1818C224.7 40.9479 224.202 40.5914 223.801 40.1388C223.085 39.3306 222.675 38.2968 222.643 37.2175C222.611 36.1382 222.958 35.0819 223.625 34.2324C224.014 33.7196 224.519 33.3075 225.1 33.0304C225.681 32.7534 226.32 32.6195 226.963 32.6401C227.625 32.6171 228.284 32.7532 228.883 33.0369C229.483 33.3206 230.005 33.7436 230.408 34.2707C231.034 35.0977 231.362 36.1122 231.338 37.1492H223.755C223.741 38.0424 224.077 38.9055 224.693 39.5531C224.979 39.8577 225.327 40.0986 225.713 40.2602C226.098 40.4218 226.514 40.5004 226.932 40.4909C227.358 40.4922 227.781 40.417 228.18 40.2689C228.558 40.1344 228.91 39.9335 229.217 39.6756C229.581 39.3257 229.891 38.9231 230.136 38.4813V38.4813ZM230.136 36.2152C230.03 35.7126 229.814 35.2395 229.504 34.8296C229.21 34.4694 228.835 34.1836 228.41 33.9951C227.962 33.7835 227.473 33.675 226.978 33.6774C226.192 33.6614 225.429 33.9462 224.846 34.4736C224.361 34.95 224.02 35.5537 223.862 36.2152H230.136Z" fill="white"/>
<path d="M241.535 32.8582V41.2794H240.467V39.8325C240.053 40.3546 239.531 40.7804 238.936 41.0804C238.13 41.4655 237.223 41.588 236.343 41.4307C235.464 41.2735 234.656 40.8444 234.033 40.2038C233.62 39.7902 233.296 39.2977 233.078 38.7558C232.86 38.2139 232.754 37.6336 232.766 37.0497C232.756 36.4713 232.865 35.8972 233.085 35.3621C233.304 34.8271 233.631 34.3424 234.044 33.9377C234.439 33.5238 234.914 33.1957 235.441 32.9737C235.968 32.7518 236.535 32.6408 237.106 32.6477C237.758 32.6375 238.403 32.7888 238.982 33.0879C239.571 33.4073 240.075 33.8611 240.456 34.4123V32.8812L241.535 32.8582ZM237.206 33.685C236.617 33.6805 236.038 33.8354 235.53 34.1332C235.022 34.4311 234.603 34.8608 234.32 35.3769C234.023 35.894 233.866 36.48 233.866 37.0765C233.866 37.6729 234.023 38.2589 234.32 38.776C234.608 39.2987 235.031 39.7348 235.545 40.0392C236.043 40.3369 236.614 40.493 237.194 40.4909C237.785 40.4918 238.366 40.3373 238.879 40.043C239.394 39.763 239.819 39.3422 240.104 38.8296C240.394 38.3028 240.542 37.7089 240.532 37.1071C240.545 36.6563 240.466 36.2076 240.301 35.7879C240.136 35.3682 239.888 34.9862 239.571 34.665C239.268 34.3481 238.902 34.0974 238.497 33.9288C238.092 33.7601 237.656 33.6772 237.217 33.685H237.206Z" fill="white"/>
<path d="M243.794 32.8582H244.877V36.7855C244.85 37.4502 244.901 38.1157 245.03 38.7683C245.175 39.2664 245.489 39.6984 245.918 39.9894C246.38 40.2967 246.926 40.4532 247.48 40.4372C248.023 40.4527 248.558 40.3003 249.011 40.0009C249.421 39.7259 249.727 39.3216 249.88 38.8525C250.024 38.1736 250.08 37.4787 250.045 36.7855V32.8582H251.151V36.9884C251.197 37.881 251.059 38.7735 250.745 39.6104C250.487 40.1852 250.06 40.6673 249.52 40.9923C248.898 41.3446 248.19 41.517 247.476 41.4899C246.758 41.5159 246.047 41.3437 245.421 40.9923C244.878 40.6649 244.449 40.1785 244.192 39.5989C243.878 38.7421 243.74 37.8308 243.786 36.9195L243.794 32.8582Z" fill="white"/>
<path d="M254.205 29.7308H255.289V32.8582H257.003V33.7921H255.289V41.2717H254.205V33.7921H252.728V32.8582H254.205V29.7308Z" fill="white"/>
<path d="M258.018 32.8582H259.166L262.06 39.3386L264.885 32.8582H266.033L261.011 44.3416H259.863L261.486 40.6248L258.018 32.8582Z" fill="white"/>
<path d="M26.6493 36.1348C34.1838 36.1348 40.2916 30.0269 40.2916 22.4924C40.2916 14.958 34.1838 8.8501 26.6493 8.8501C19.1148 8.8501 13.007 14.958 13.007 22.4924C13.007 30.0269 19.1148 36.1348 26.6493 36.1348Z" fill="#EF4D5B"/>
<path d="M29.214 14.1669H18.7449C18.2185 14.1669 17.7917 14.5936 17.7917 15.12V17.1717C17.7917 17.6981 18.2185 18.1248 18.7449 18.1248H29.214C29.7403 18.1248 30.1671 17.6981 30.1671 17.1717V15.12C30.1671 14.5936 29.7403 14.1669 29.214 14.1669Z" fill="white"/>
<path d="M31.4455 20.3948H20.9764C20.45 20.3948 20.0233 20.8215 20.0233 21.3479V23.3996C20.0233 23.926 20.45 24.3527 20.9764 24.3527H31.4455C31.9719 24.3527 32.3986 23.926 32.3986 23.3996V21.3479C32.3986 20.8215 31.9719 20.3948 31.4455 20.3948Z" fill="white"/>
<path d="M34.5308 26.6189H24.0618C23.5354 26.6189 23.1086 27.0456 23.1086 27.572V29.6237C23.1086 30.1501 23.5354 30.5769 24.0618 30.5769H34.5308C35.0572 30.5769 35.484 30.1501 35.484 29.6237V27.572C35.484 27.0456 35.0572 26.6189 34.5308 26.6189Z" fill="white"/>
<path d="M0 22.4742C1.72366 20.7675 3.38206 19.1104 5.05966 17.4686C5.18507 17.3574 5.28442 17.2203 5.35074 17.067C5.41706 16.9137 5.44874 16.7478 5.44355 16.581C5.44355 14.1277 5.44355 11.6706 5.44355 9.21354C5.41899 8.00623 5.64052 6.80644 6.09484 5.68619C6.54917 4.56594 7.22691 3.54834 8.08739 2.69446C8.94786 1.84059 9.97334 1.16805 11.1022 0.717216C12.2312 0.266382 13.4402 0.0465506 14.6569 0.0709169H19.9046V3.7813H14.7797C11.4629 3.7813 9.17111 6.03648 9.15959 9.32402C9.15959 12.2725 9.15959 15.2197 9.15959 18.1657C9.16479 18.3325 9.13311 18.4984 9.06679 18.6517C9.00047 18.8051 8.90111 18.9421 8.7757 19.0533C7.74688 20.0437 6.74493 21.0609 5.72379 22.0589C5.56706 22.1803 5.39708 22.2838 5.21705 22.3675C5.45506 22.638 5.56255 22.7484 5.67772 22.8818C6.7027 23.9027 7.72001 24.9274 8.74883 25.9293C8.87839 26.0462 8.98006 26.1903 9.04647 26.3511C9.11287 26.5119 9.14234 26.6853 9.13272 26.8588C9.13272 29.7895 9.13272 32.7202 9.13272 35.651C9.13272 38.1271 10.4456 40.089 12.6491 40.8432C13.2792 41.0478 13.9365 41.1583 14.5993 41.1708C16.1349 41.2204 17.6704 41.1708 19.206 41.1708H19.8586V44.8279C19.6743 44.8279 19.4747 44.8545 19.3135 44.8545H14.3766C12.0104 44.8456 9.74327 43.9109 8.06723 42.2535C6.39118 40.596 5.44152 38.3494 5.42435 36.0014C5.409 33.4618 5.409 30.9222 5.42435 28.3826C5.43058 28.2162 5.39936 28.0506 5.33295 27.8977C5.26654 27.7448 5.16662 27.6085 5.04046 27.4988C3.50491 25.975 1.96935 24.4513 0.433795 22.9275C0.303272 22.8094 0.17275 22.657 0 22.4742Z" fill="#0178B2"/>
<path d="M33.5852 45V41.2985C33.7996 41.2985 33.9948 41.2985 34.19 41.2985C35.9776 41.2985 37.769 41.3559 39.5489 41.2258C40.8373 41.107 42.0343 40.5097 42.9038 39.5516C43.7733 38.5935 44.2521 37.3443 44.2457 36.0506C44.2457 32.9692 44.2457 29.8916 44.2457 26.8102C44.2602 26.517 44.3766 26.2381 44.5749 26.0217C45.5892 24.9652 46.6342 23.9394 47.6677 22.9058C47.7979 22.7757 47.9242 22.6379 48.0926 22.4618C46.9902 21.3709 45.9337 20.2876 44.8352 19.2503C44.628 19.0701 44.4645 18.8452 44.3571 18.5925C44.2496 18.3399 44.2011 18.0661 44.2151 17.7919C44.2495 14.9325 44.2151 12.0731 44.2151 9.21374C44.2151 5.99072 41.9566 3.72082 38.7298 3.69786C37.1987 3.69786 35.6675 3.69786 34.1364 3.69786H33.589V0.0690873C33.6465 0.0346369 33.6732 0.00401435 33.7039 0.00401435C36.0312 0.053776 38.3891 -0.122303 40.6781 0.203061C44.8887 0.804028 47.974 4.64716 47.974 8.90369C47.974 11.4568 47.974 14.01 47.974 16.5593C47.966 16.7179 47.993 16.8762 48.0533 17.0231C48.1135 17.17 48.2053 17.3018 48.3223 17.4091C49.9683 19.0372 51.6078 20.6717 53.241 22.3125C53.2954 22.3736 53.3465 22.4375 53.3942 22.5039C53.2678 22.6379 53.1454 22.7719 53.0114 22.8867C51.4343 24.4638 49.8534 26.0332 48.2917 27.6255C48.1022 27.8361 47.9948 28.1079 47.9893 28.3911C47.9663 30.9596 47.9893 33.5319 47.9893 36.1003C47.9772 38.4589 47.0341 40.7173 45.3652 42.384C43.6963 44.0508 41.4367 44.9909 39.0781 45H33.5852Z" fill="#0178B2"/>
</svg>

  </div>
</template>

<script>
export default {
  props: ["class"],
};
</script>
