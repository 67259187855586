<template>
  <Header :class="hidebanner && 'bg-blue'"  />
  <slot></slot>
  <Footer :showReadMore="showReadMore" />
</template>

<script>
import Header from "./Header";
import Footer from "@/components/Footer";

export default {
  components: { Header, Footer },
  props: {
    hidebanner: {
      default: false,
    },
    showReadMore: {
      default: true,
    },
  },

  mounted() {
    this.setContactsDetails();

    let showNav = false;

    const mobileNav = document.querySelector(".mobile-nav");
    const mobileNavIcon = document.querySelector("#mobile-nav-icon");
    const mobileNavItems = document.querySelector(".mobile-nav-items");

    const closeNav = document.querySelector(".close-nav");

    const toggleNav = () => {
      if (showNav) {
        mobileNavIcon.classList.remove("block");
        mobileNavIcon.classList.add("hidden");

        mobileNav.classList.add("mobile-nav-show");
        mobileNavItems.classList.add("mobile-nav-items-show");
      } else {
        mobileNavIcon.classList.remove("hidden");
        mobileNavIcon.classList.add("block");

        mobileNav.classList.remove("mobile-nav-show");
        mobileNavItems.classList.remove("mobile-nav-items-show");
      }
    };

    closeNav.onclick = (e) => {
      e.preventDefault();
      showNav = false;
      document.body.classList.remove("overflow-hidden");
      toggleNav();
    };

    mobileNavIcon.onclick = () => {
      showNav = true;
      document.body.classList.add("overflow-hidden");
      toggleNav();
    };
  },

  methods: {
    setContactsDetails() {
      const email = document.getElementsByClassName("email");
      const emailClick = document.getElementsByClassName("email-click");

      for (const el of emailClick) {
        el.onclick = () => {
          location.href = `mailto:contact@indentations.io`;
        };
      }

      for (const el of email) {
        el.onclick = () => {
          location.href = `mailto:contact@indentations.io`;
        };
        el.innerHTML =
          '<div class="flex">con<ul class="flex">tact<li class="hidden">hide</li><li class="block">@</li><li>indenta</li>tion<li class="hidden">s</li><li>s.i</li>o</div>';
      }

      const phone = document.getElementsByClassName("phone");
      const phoneclick = document.getElementsByClassName("phone-click");

      for (const el of phoneclick) {
        el.onclick = () => {
          location.href = `tel:1-347-960-3842`;
        };
      }

      for (const el of phone) {
        el.innerHTML =
          '<div class="flex">1<ul class="flex">-34<li class="hidden">2383</li><li>7-</li><li>960-</li><li>38</li>42</div>';
      }
    },

    getSelectedText() {
      var selectedText = "";

      // window.getSelection
      if (window.getSelection) {
        selectedText = window.getSelection();
      }
      // document.getSelection
      else if (document.getSelection) {
        selectedText = document.getSelection();
      }
      // document.selection
      else if (document.selection) {
        selectedText = document.selection.createRange().text;
      } else return;
      // To write the selected text into the textarea
      document.testform.selectedtext.value = selectedText;
    },
  },
};
</script>

<style lang="scss">
body {
}

#app {
  // min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.bg-circle {
  background-image: url(../assets/body-bg-left.png), url(../assets/body-bg-right.png);
  background-position: bottom left , top right;
  background-repeat: no-repeat, no-repeat;
  background-size: contain, contain;
}

.banner {
  // @apply bg-cover bg-no-repeat bg-center -mt-28 pt-56 2xl:pb-7 xl:pb-7 lg:pb-7 md:pb-7 pb-0 mb-auto;
  @apply bg-cover bg-no-repeat  -mt-28  flex items-center;
  background-image: url(../assets/indentation-banner-background.png);
  background-position: bottom;
}
</style>
