<template>
	<div class="md:text-left text-center">
		<div class="inline-block text-gray-100">
			<h5 class="uppercase">
				<span class="text-red font-bold">
					browse
				</span>
				our site
			</h5>

			<div class="pt-3 footer-nav">
				<ul>
					<li><a href="javascript:void(0)" class="pb-0.5 text-sm"  @click="moveTo('/')">HOME</a></li>
					<!-- <li class="pt-2"><a href="javascript:void(0)" class="pb-0.5  text-sm" @click="moveTo('/blog')">BLOG</a></li> -->
					<li class="pt-2"><a href="javascript:void(0)" class="pb-0.5  text-sm" @click="moveTo('/contact')">CONTACT</a></li>
					<li class="pt-2"><a href="javascript:void(0)" class="pb-0.5  text-sm" @click="moveTo('/about')">ABOUT</a></li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	mounted() {
		this.setActiveNav();
	},

	methods: {
		async moveTo(route) {
			const el = document.querySelector(
				".footer-nav ul li.active-nav"
			);
			if (el) {
				el.classList.remove("active-nav");
				window.location.href = route;
			}
		},

		setActiveNav() {
			let pathname = new URL(location.href).pathname;
			 pathname = "/"+pathname.split('/')[1];
			const navList = document.querySelectorAll(".footer-nav ul li");

            if(!navList) return;

			const routes = ["/","/contact", "/about"];

			let index = routes.indexOf(pathname);

			if (!index) {
				index = 0;
			}

			navList[index].classList.add("active-nav");
		},
	},
};
</script>

<style scoped lang="scss">
.active-nav a {
	@apply border-b-2 border-red;
}

ul li {
	a:hover,
	a:focus,
	a:active {
		@apply border-b-2 border-red;
	}
}
</style>
