<template>
  <footer
    class="px-10 pb-8 pt-16 bg-blue-800 relative m-t-auto"
  >
    <div class="mx-auto container max-w-screen-xl text-gray-100">
      <div class="sm:flex block">
        <div class="flex-1">
          <div
            class="flex items-center flex-col sm:items-start max-w-xs 
               md:max-w-full mx-auto"
          >
            <div class="xl:w-72 lg:w-64 w-60">
              <a href="/">
                <Logo />
              </a>
            </div>

            <p class="text-center sm:text-left pt-4 max-w-xs text-sm">
              Indentations LLC provides custom software solutions for businesses
              and individuals.
            </p>
            <div class="pt-4 flex justify-center sm:justify-start w-full" v-if="showReadMore">
              <a
                href="/about"
                class="rounded-full w-full tracking-wider sm:w-auto py-3 text-center leading-none px-8 text-sm md:text-base uppercase bg-red hover:bg-red-700"
              >
                READ MORE
              </a>
            </div>
          </div>
        </div>

        <div class="flex-1 mt-10 md:mt-0 hidden md:flex justify-center">
          <BrowseOurSite />
        </div>

        <div
          class="flex-1 text-center flex md:justify-end justify-center mt-10 md:mt-0"
        >
          <ContactInformation />
        </div>
      </div>
    </div>

    <div
      class="text-center justify-center w-full text-white flex flex-col md:flex-row pt-10"
    >
      <p class="mx-1 pt-2  text-sm">
        <b class="text-red">Indentations LLC</b> &copy; 2021 All rights
        reserved.
      </p>

      <p class="mx-1 pt-2  text-sm">
        <a href="/terms-of-use" class="border-white border-b pt-2"> Terms of use</a>
        <span class="border-r mx-2 h-8 border-opacity-40"></span>
        <a href="/privacy-policy" class="border-white border-b pt-4">Privacy policy</a>
      </p>
    </div>
  </footer>
</template>

<script>
import Logo from "./Logo";
import ContactInformation from "./ContactInformation";
import BrowseOurSite from "./BrowseOurSite";

export default {
  props :{
    showReadMore: {
      default: true
    }
  },
  components: {
    Logo,
    BrowseOurSite,
    ContactInformation,
  },

  computed: {
  
  },
};
</script>

